<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs"
    :key="isSmallerScreen">
    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'System' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card>
          <h6 class="mb-4">Setting System</h6>
          <vs-table search stripe :data="system">

            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>setting</vs-th>

            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.setting_name">
                  {{tr.setting_name}}
                </vs-td>
                <vs-td :data="tr.setting_status">
                  <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" v-model="tr.setting_status" @click="update_status(tr.setting_no,tr.setting_status,tr.setting_name,'System')">
                    <span slot="on">เปิด</span>
                    <span slot="off">ปิด</span>
                  </vs-switch>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'Game' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card>
          <h6 class="mb-4">Setting Game</h6>
          <vs-table stripe :data="game">

            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>setting</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.setting_name">
                  {{tr.setting_name}}
                </vs-td>
                <vs-td :data="tr.setting_status">
                  <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" v-model="tr.setting_status" @click="update_status(tr.setting_no,tr.setting_status,tr.setting_name,'Game')">
                    <span slot="on">เปิด</span>
                    <span slot="off">ปิด</span>
                  </vs-switch>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'Bonus' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card>
          <h6 class="mb-4">Setting Bonus </h6>
          <vs-table stripe :data="bonus">

            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>setting</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.bonus_name">
                  {{tr.bonus_name}}
                </vs-td>
                <vs-td :data="tr.bonus_button">
                  <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" v-model="tr.bonus_button" @click="update_status(tr.bonus_id,tr.bonus_button,tr.bonus_name,'Bonus')">
                    <span slot="on">เปิด</span>
                    <span slot="off">ปิด</span>
                  </vs-switch>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </vs-tab>

    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'URL' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card>
          <h6 class="mb-4">Setting URL </h6>
          <vs-table stripe :data="url">

            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>URL DESKTOP</vs-th>
              <vs-th>URL MOBILE</vs-th>
              <vs-th>ICON</vs-th>
              <vs-th> </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.bonus_name">
                  {{tr.text}}
                </vs-td>
                <vs-td>
                  {{tr.url}}
                </vs-td>
                <vs-td >
                  {{tr.urlMobile}}
                </vs-td>
                <vs-td>
                  {{ tr.icon }}
                </vs-td>
                <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" type="border" size="small" icon-pack="feather" icon="icon-edit" color="warning" class="mr-2"
                @click="$router.push('/EditUrl/'+tr.id)">แก้ไขลิงค์ทางเข้า
                </vs-button>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </vs-tab>

    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'LinkLine' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card>
          <h6 class="mb-4">Setting LinkLine </h6>
          <br>

          <vs-table search stripe :data="line">

          <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>setting</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.description">
                {{tr.description}}
              </vs-td>
              <vs-td :data="tr.value">
                <vs-input class="w-full " v-validate="'required'" label-placeholder="value" v-model="tr.value" name="link"></vs-input>
              </vs-td>
              <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" class="ml-auto mt-2" color="success" @click="save(tr.type,tr.value,tr.description)" >บันทึก</vs-button>
            </vs-tr>
          </template>
          </vs-table>
        </vx-card>
      </div>
    </vs-tab>

  </vs-tabs>


</template>

<script>
import axios from '../../axios'


export default {
  data () {
    return {
      game: [],
      system: [],
      bonus: [],
      url:[],
      tset:'',
      onoff_status:{},
      line:[],
      lnikLineResponse:{}
    }
  },
  async mounted () {
    await axios
      .get('settingsystem/settingsystem/game')
      .then(response => (this.game = response.data))
    await axios
      .get('settingsystem/settingsystem/system')
      .then(response => (this.system = response.data))
    await axios
      .get('settingsystem/settingsystem/bonus')
      .then(response => (this.bonus = response.data))
    await axios
      .get('settingsystem/settingsystem/url')
      .then(response => (this.url = response.data))
    await axios
      .get('settingsystem/settingline/')
      .then(response => (this.line = response.data))
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  methods: {
    async checktype (type, value) {
      if (type === 'link') {
        this.savelink(type, value)
      } else {
        this.savetype(type, value)
      }
    },
    async save (type, value, description) {
      await axios
        .post('settingsystem/systemlineupdate', {
          type,
          value
        }).then(response => (this.lnikLineResponse = response.data))
      if (this.lnikLineResponse.status === true) {
        this.$vs.notify({
          time:3000,
          color: 'success',
          position:'top-right',
          icon:  'check_box',
          title: 'แก้ไขสำเร็จ',
          text: `${description}ที่แก้ไข : ${value}`
        })

      } else {
        this.$vs.notify({
          time:8000,
          color: 'danger',
          position:'top-right',
          icon:  'error',
          title: 'แก้ไขไม่สำเร็จ',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async update_status (setting_no, setting_status, setting_name, system_name) {
      // console.log('setting_no', setting_no, 'setting_status', setting_status, 'setting_name', setting_name)
      if (setting_status === true) {
        setting_status = false
      } else {
        setting_status = true
      }
      await axios
        .post('settingsystem/systemstatus', {
          no:setting_no,
          status:setting_status,
          name:setting_name,
          bonus:system_name
        }).then(response => (this.onoff_status = response.data))
      if (this.onoff_status.status === true) {
        this.$vs.notify({
          time:3000,
          color: setting_status === true ? 'success' : 'warning',
          position:'top-right',
          icon:  setting_status === true ? 'check_box' : 'error',
          title: setting_status === true ? 'เปิด' : 'ปิด',
          text:  setting_status === true ? `ระบบ : ${setting_name}` : `ระบบ : ${setting_name}`
        })


      } else {
        this.$vs.notify({
          time:8000,
          color: 'danger',
          position:'top-right',
          icon:  'error',
          title: this.onoff_status.status === false ? 'เปิดระบบ หน้าเว็บไม่สำเร็จ!!' : 'ปิดระบบ หน้าเว็บไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
        if (setting_status === true) {
          setting_status = true
        } else if (setting_status === false) {
          setting_status = false
        }
      }
    }
  }

}

</script>

<style lang="scss">
  #profile-tabs {
    .vs-tabs--content {
      padding: 0;
    }
  }

</style>
